import { post } from './http.js'
import octokit from './octokit.js'

export const upload = formData => {
  return post('https://imgs.top/api/v1/upload', formData)
}

export const oauthGithub = data => {
  return post(`https://api.wqdy.top/api/oauth-github?code=${data}`)
}

export const getLabels = async () => {
  const response = await octokit.request('GET /repos/{owner}/{repo}/labels', {
    owner: 'wqdygkd',
    repo: 'webstack-vue'
  })
  return response.data || []
}

async function getIssuesApi (page, pre) {
  pre = pre || []
  const response = await octokit.request('GET /repos/{owner}/{repo}/issues', {
    owner: 'wqdygkd',
    repo: 'webstack-vue',
    per_page: 100,
    page
  })
  const data = response.data || []
  if (data.length === 100) {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(1)
      }, 1000)
    })

    return await getIssuesApi(page + 1, [...pre, ...data])
  } else {
    return [...pre, ...data]
  }
}

export const getIssues = async () => {
  const data = await getIssuesApi(1)
  return data
}
